import jQuery from 'jquery';

jQuery('section.announcements').each((_, announcements) => {
	const acknowledgmentUrl = $(announcements).data("acknowledgment-url");
	
	$(announcements).on("click", ".announcement > .close", e => {
		const announcementId = $(e.currentTarget).data("id");

		jQuery.post(
			acknowledgmentUrl,
			{ Id: announcementId }
		);
	});
})
