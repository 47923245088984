import './fragments/panel-link';
import './fragments/fixed-height-toggle';
import './fragments/announcements';
import './style/index.less';
import { bind as bindTree } from '../../../components/tree/Tree';
import { initialize as initializeParameterBinding } from './fragments/parameter-binding'

export default function initialize() {
	bindTree();
	initializeParameterBinding();
}
